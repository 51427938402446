import React, { FC, useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { usePagination } from '@/hooks/use-pagination';
import styles from './index.module.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages }) => {
  const router = useRouter();
  const { buildPageUrl } = usePagination();

  const handlePrevious = useCallback(() => {
    if (currentPage > 1) {
      const pageUrl = buildPageUrl(currentPage - 1);
      if (pageUrl) {
        router.push(pageUrl, { scroll: true });
      }
    }
  }, [buildPageUrl, currentPage, router]);

  const handleNext = useCallback(() => {
    if (currentPage < totalPages) {
      const pageUrl = buildPageUrl(currentPage + 1);
      if (pageUrl) {
        router.push(pageUrl, { scroll: true });
      }
    }
  }, [buildPageUrl, currentPage, router, totalPages]);

  const renderPages = useCallback(() => {
    const pages = [];
    let ellipsisBefore = false;
    let ellipsisAfter = false;

    for (let i = 1; i <= totalPages; i += 1) {
      // Conditions to check if page number should be visible directly
      const shouldDisplayNumber =
        i === 1 || i === 2 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1);

      if (shouldDisplayNumber) {
        const active = currentPage === i;
        const pageUrl = buildPageUrl(i);
        const disabled = active || !pageUrl;
        pages.push(
          <Link
            key={`page-link-${i}`}
            href={pageUrl || ''}
            className={active ? styles.paginationPagesButtonActive : styles.paginationPagesButton}
            aria-disabled={disabled}
            tabIndex={disabled ? -1 : undefined}
          >
            {i}
          </Link>
        );
      } else if (i < currentPage && !ellipsisBefore) {
        pages.push(
          <div key={`before-${i}`} className={styles.threeDots}>
            ...
          </div>
        );
        ellipsisBefore = true;
      } else if (i > currentPage && !ellipsisAfter) {
        pages.push(
          <div key={`after-${i}`} className={styles.threeDots}>
            ...
          </div>
        );
        ellipsisAfter = true;
      }
    }

    return pages;
  }, [buildPageUrl, currentPage, totalPages]);

  return totalPages > 1 ? (
    <div className={styles.paginationContainer}>
      <nav className={styles.paginationNav} aria-label='pagination'>
        <div className={styles.paginationPrev}>
          <button
            type='button'
            onClick={handlePrevious}
            className={styles.paginationBtn}
            disabled={currentPage === 1}
            aria-disabled={currentPage === 1}
            aria-label='Previous'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
              className={styles.svgIconPrevious}
            >
              <path
                fillRule='evenodd'
                d='M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z'
                clipRule='evenodd'
              />
            </svg>
            <span className='hidden md:flex lg:flex'>Previous</span>
          </button>
        </div>
        <div className={styles.paginationPagesContainer}>{renderPages()}</div>
        <div className={styles.paginationNext}>
          <button
            type='button'
            onClick={handleNext}
            className={styles.paginationBtn}
            disabled={currentPage === totalPages}
            aria-disabled={currentPage === totalPages}
            aria-label='Next'
          >
            <span className='hidden md:flex lg:flex'>Next</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
              className={styles.svgIconNext}
            >
              <path
                fillRule='evenodd'
                d='M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  ) : null;
};

export default Pagination;
