'use client';

import { MEDIUM_SCREEN_SIZE } from '@/constants';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import debounce from 'lodash-es/debounce';
import React, { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';

export type Rule = 'always' | 'extended';

export type DecShowFn = () => void;
export type IncShowFn = (rule: Rule) => DecShowFn;
export type ZendeskChatbotActions = {
  // show is refcounted, the returned function must be invoked at the and of scope
  incShow: IncShowFn;
};
export const ZendeskChatbotContext = createContext<ZendeskChatbotActions | undefined>(undefined);

const isBrowser = typeof window === 'object';

/**
 * Zendesk chatbot script loader, lifted from legacy store
 *
 * @param onLoad Callback on/if the script is loaded
 * @returns
 */
const loadZendeskScript = (onLoad: () => void) => {
  const zKey = process.env.NEXT_PUBLIC_ZENDESK_CHAT_KEY;
  if (!zKey) {
    return;
  }
  const liveChatScript = `https://static.zdassets.com/ekr/snippet.js?key=${zKey}`;
  const zendeskScript = document.createElement('script');
  zendeskScript.id = 'ze-snippet';
  zendeskScript.src = liveChatScript;
  zendeskScript.onload = onLoad;
  document.body.appendChild(zendeskScript);
};

/**
 * Helper to get Zenddesk chatbox zE function, if loaded/present/etc.
 *
 * @returns zE as generic Function, or undefined
 */
const getZe = (): Function | undefined => {
  if (isBrowser && 'zE' in window && typeof window.zE === 'function') {
    return window.zE;
  }
  return undefined;
};

/**
 * simplified version of react-use-zendesk's API implementation
 * https://github.com/multivoltage/react-use-zendesk/blob/5c0cff58358d94daabdcb4ac49323d5ff3b8bee5/packages/react-use-zendesk/src/api.ts
 *
 * @param scope Target of method
 * @param method Action to incoke
 * @param args Args, if any, for the method
 */
export const callZe = (scope: 'messenger', method: 'show' | 'hide', ...args: Array<unknown>) => {
  const zE = getZe();
  if (zE) {
    zE.apply(null, [scope, method, ...args]);
  }
};

// lightly debounced show/hide calls to reduce chance of a blinking chatbot while transitioning
const debouncedCallZe = debounce(callZe, 100, { maxWait: 500 });

export type ZendeskChatbotProviderProps = PropsWithChildren;

export const ZendeskChatbotProvider = ({ children }: ZendeskChatbotProviderProps) => {
  const [counters, setCounts] = useState<Record<Rule, number>>({ always: 0, extended: 0 });
  const { width } = useWindowDimensions();

  type LoadState = 'init' | 'loading' | 'loaded';
  const [loadState, setLoadState] = useState<LoadState>('init');

  // immediately execute debounced callZe() on the way out
  // (or, technically, if the debouncedCallZe instance changes)
  // we could also consider hiding/unloading here, but currently
  // we don't add/remove the provider so just leave it as-is until
  // there is a use case that dictates the behavior
  useEffect(() => {
    return () => {
      debouncedCallZe.flush();
    };
  }, []);

  // Context function to show chatbot in a given rule
  // All modification replace the `counters` object instance for easier
  // downwind change detection.
  const incShow = useCallback<IncShowFn>((rule: Rule) => {
    const tweakCount = (delta: number) => {
      setCounts((prev) => {
        return {
          ...prev,
          [rule]: prev[rule] + delta,
        };
      });
    };

    // Add one to rule counter
    tweakCount(1);

    // Return a cleanup function, to undo that add
    return () => {
      tweakCount(-1);
    };
  }, []);

  // Determine if the chatbot should be shown for the current display rule
  const show = useMemo(() => {
    if (!isBrowser) {
      return false;
    }

    if (counters.always > 0) {
      return true;
    }

    const showExtended = width && width > MEDIUM_SCREEN_SIZE;
    if (showExtended && counters.extended > 0) {
      return true;
    }

    return false;
  }, [counters.extended, counters.always, width]);

  // Show/hide/load chatbot
  useEffect(() => {
    if (show) {
      if (loadState === 'init') {
        setLoadState('loading');
        // note: if this load fails this will stall here
        // which should be just fine (other than no chatbot
        // if one was desired) and may just mean the zendesk
        // key env var just isn't set
        loadZendeskScript(() => {
          setLoadState('loaded');
        });
      } else if (loadState === 'loaded') {
        // show
        debouncedCallZe('messenger', 'show');
      }
    } else if (loadState === 'loaded') {
      // hide
      debouncedCallZe('messenger', 'hide');
    }
  }, [show, loadState]);

  // Memoize state for context
  const dataMemo = useMemo<ZendeskChatbotActions>(() => {
    return { incShow };
  }, [incShow]);

  return <ZendeskChatbotContext.Provider value={dataMemo}>{children}</ZendeskChatbotContext.Provider>;
};
