'use client';

import { useEffect } from 'react';
import { useUiState } from '@/stores/ui-state';

/**
 * Hook to enable header nav underline for the current page
 */
export function useEnableNavUnderline() {
  const [navUnderlineInc, navUnderlineDec] = useUiState((state) => [state.navUnderlineInc, state.navUnderlineDec]);

  useEffect(() => {
    // increment the underline 'on' counter
    navUnderlineInc();
    return () => {
      // free our ref
      navUnderlineDec();
    };
  }, [navUnderlineInc, navUnderlineDec]);
}
