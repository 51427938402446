'use client';

import { useCallback, useEffect, useState } from 'react';
import { SEARCH_PARAM_PAGE } from '@/constants';
import { usePathname, useSearchParams } from 'next/navigation';

/**
 * Make sure query bit of a url starts with a question mark, if there are query args
 *
 * @param query
 * @returns
 */
function normalizeQuery(query: string): string {
  if (query && !query.startsWith('?')) {
    return `?${query}`;
  }
  return query;
}

/**
 * Hook to help manage created pagination urls
 * @returns buildPageUrl() function to generate paged urls (buildPageUrl() will return
 *  null on server and initial client calls - see note below)
 */
export function usePagination(): {
  buildPageUrl: (pageNo: number) => string | null;
} {
  const searchParams = useSearchParams();

  // Pass through useEffect() to get rid of ssr rewrite cruft.
  //
  // from: https://nextjs.org/docs/app/api-reference/functions/use-pathname
  //
  // When using usePathname with rewrites in next.config or Middleware, useState
  // and useEffect must also be used in order to avoid hydration mismatch errors.
  const rawPathname = usePathname();
  const [pathname, setPathname] = useState<string | null>(null);
  useEffect(() => {
    setPathname(rawPathname);
  }, [rawPathname]);

  const buildPageUrl = useCallback(
    (pageNo: number): string | null => {
      if (!pathname) {
        return null;
      }

      const params = new URLSearchParams(searchParams.toString());
      if (pageNo > 1) {
        // tweak the page
        params.set(SEARCH_PARAM_PAGE, `${pageNo}`);
      } else {
        // don't use a page number for 1, so the client router doen't
        // think there is a difference between the default page and
        // page 1 (with a separate hit to the backend)
        params.delete(SEARCH_PARAM_PAGE);
      }

      return `${pathname}${normalizeQuery(params.toString())}`;
    },
    [pathname, searchParams]
  );

  return { buildPageUrl };
}
