'use client';

import { ProductFragment, BasicProductFragment } from '@/gql';

export type ProductKeyFactory = (product: ProductFragment | BasicProductFragment) => string;

export const useProductKeyFactory = (): ProductKeyFactory => {
  // not memoizing or caching here, each run gets a new factory
  const skuCount: Map<String, number> = new Map();

  return (product: ProductFragment | BasicProductFragment) => {
    let sku = '';
    if ('primaryProductSku' in product) {
      sku = product.primaryProductSku || '';
    } else {
      sku = product.id?.toString() || '';
    }
    const counter = skuCount.get(sku) || 0;
    const suffix = counter ? `_${counter}` : '';
    skuCount.set(sku, counter + 1);
    return `${sku}${suffix}`;
  };
};
