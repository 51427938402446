'use client';

import { useContext, useEffect } from 'react';
import { ZendeskChatbotContext, Rule } from '@/contexts/zendesk-chatbot-context';

export const useZendeskChatbot = (rule: Rule): void => {
  const context = useContext(ZendeskChatbotContext);
  if (context === undefined) {
    throw new Error('Error: useZendeskChatbot must be used within a ZendeskChatbotContext');
  }

  useEffect(() => {
    // note that we're returning a cleanup function
    // which will decrement when the effect expires
    return context.incShow(rule);
  }, [context, rule]);
};
