'use client';

import { Rule } from '@/contexts/zendesk-chatbot-context';
import { useZendeskChatbot } from '@/hooks/use-zendesk-chatbot';
import React, { PropsWithChildren } from 'react';

export type Props = {
  rule: Rule;
};

const ZendeskChatbotWrapper: React.FC<PropsWithChildren<Props>> = ({ rule, children }) => {
  useZendeskChatbot(rule);
  // wrap in fragment to work around type issue returning a ReactNode
  // see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/44572
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ZendeskChatbotWrapper;
