'use client';

import React, { Suspense, useCallback, useEffect, useState, lazy, useMemo } from 'react';
import QuickView from '@/components/QuickView';
import ProductTileSkeleton from '@/components/ProductTileSkeleton';
import { PrimaryProductFragment, ProductFragment } from '@/gql';
import { useListingUrl } from '@/hooks/use-listing-url';
import { useStoreData } from '@/hooks/use-store-data';
import { useProductColorOptions } from '@/hooks/use-product-color-options';
import analytics from '@/lib/analytics';
import { buildSortedPrimaryProductImages, selectProductVariant } from '@/lib/product';
import { ColorOption } from '@/types/color-option';
import styles from './index.module.css';

// how many color swatches should the color picker initialially show?
const COLOR_PREVIEW_COUNT = 6;
// common global empty product array, global to be steady an not cause down stream re-renders
const DEFAULT_PRODUCTS: ReadonlyArray<PrimaryProductFragment> = [];

const ProductVisualDisplay = lazy(() => import('@/components/ProductVisualDisplay'));
const ColorComponent = lazy(() => import('@/components/ColorComponent'));

const ProductTile = ({
  product,
  productTileContainerClass,
}: {
  product: ProductFragment;
  productTileContainerClass?: string;
}) => {
  const primaryProducts = product.primaryProduct || DEFAULT_PRODUCTS;

  const { colors } = useProductColorOptions(product);
  const [selectedColorId, setSelectedColorId] = useState<ColorOption['id'] | undefined>();
  const [quickViewOpen, setQuickViewOpen] = useState(false);

  useEffect(() => {
    // Need to pick a default color/variant?
    if (selectedColorId === undefined || !colors.find((c) => c.id === selectedColorId)) {
      // Try to select the first in-stock color/variant (defaulting to first)
      let defaultIndex = 0;
      // Use library helper to pick a good default variant
      const defaultPrimaryProduct = selectProductVariant(product);
      if (defaultPrimaryProduct?.variationId) {
        // Find color for selected color/variant
        const tempIndex = colors.findIndex((c) => c.id === defaultPrimaryProduct.variationId);
        // Did we find something?
        if (tempIndex >= 0) {
          defaultIndex = tempIndex;
        }
      }
      setSelectedColorId(defaultIndex < colors.length ? colors[defaultIndex].id : undefined);
    }
  }, [colors, product, selectedColorId]);

  const listingUrl = useListingUrl(product, selectedColorId);
  const { storeData } = useStoreData();
  const onColorClick = useCallback(
    (colorId: number) => {
      setSelectedColorId(colorId);
      analytics.track('color_clicked', { productId: product.primaryProductId, source: 'Product Tile' }, storeData);
    },
    [product.primaryProductId, storeData]
  );

  const handleQuickView = () => {
    setQuickViewOpen(true);
  };

  const quickViewButton = () => {
    return (
      <button type='button' onClick={handleQuickView} className={styles.productReviewButton} aria-label='Quick View'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className={styles.reviewIcon}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
          />
          <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
        </svg>
      </button>
    );
  };

  // handle review on thumbnail hover and on color click
  // Find the selected product based on the selected color ID
  const firstProduct = primaryProducts.find((p) => p.variationId === selectedColorId) || primaryProducts[0];
  const orderedProductImages = useMemo(
    () => buildSortedPrimaryProductImages(firstProduct, product.listingThumbnails?.primary || undefined),
    [firstProduct, product.listingThumbnails?.primary]
  );

  // try to show the first (which at least now is the default) size price, but support
  // falling back to the generic variant price (which may not be a valid price for any
  // real size)
  const price = firstProduct?.sizes?.[0]?.price || firstProduct?.price || '';

  return (
    <div className={productTileContainerClass} data-testid='product-tile'>
      {' '}
      {/** TODO: still need to test this */}
      {quickViewOpen && (
        <QuickView
          product={product}
          variationId={selectedColorId ?? undefined}
          isOpen={quickViewOpen}
          setIsOpen={setQuickViewOpen}
        />
      )}
      <Suspense fallback={<ProductTileSkeleton />}>
        <ProductVisualDisplay
          productId={product.id || ''}
          productTitle={product.title || ''}
          images={orderedProductImages}
          listingUrl={listingUrl || ''}
          price={price}
          currency={product.currency || ''}
          actionElement={quickViewButton()}
          productType={firstProduct?.productType || product.primaryProductType || ''}
        />
        {colors?.length > 1 && (
          <>
            <h4 className='sr-only'>Available colors</h4>
            <ColorComponent
              colorClassName={styles.colorClass}
              selectedColorClassName={styles.selectedColorClass}
              colorsContainerClassName={styles.colorsContainerClass}
              labelClass={styles.labelClass}
              colors={colors}
              selectedColorId={selectedColorId}
              previewCount={COLOR_PREVIEW_COUNT}
              onColorClick={onColorClick}
            />
          </>
        )}
      </Suspense>
    </div>
  );
};

export default ProductTile;
