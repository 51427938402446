import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Product from '@/components/Product';
import AddedToCart from '@/components/AddedToCart';
import Overlay from '@/components/Overlay';
import { ProductFragment, PrimaryProductFragment } from '@/gql';
import { selectProductVariant, selectVariantSize } from '@/lib/product';
import analytics from '@/lib/analytics';
import { useStoreData } from '@/hooks/use-store-data';
import { CartItem } from '@/types/cart';
import { XMarkIcon } from '@heroicons/react/24/outline';
import styles from './index.module.css';

interface QuickViewProps {
  product: ProductFragment;
  variationId: number | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const QuickView: React.FC<QuickViewProps> = ({ product, variationId, isOpen, setIsOpen }) => {
  const [addedCartItem, setAddedCartItem] = useState<CartItem | undefined>();
  const [showAddedToCart, setShowAddedToCart] = useState(false);

  const { storeData } = useStoreData();
  useEffect(() => {
    analytics.track(
      'quickview_viewed',
      {
        productId: product?.primaryProductId ?? null,
        campaignRootId: product?.primaryProduct?.[0].teespringId ?? null,
        variationId: variationId ?? null,
        currency: product?.currency ?? null,
        price: product?.primaryProduct?.[0]?.price ?? 0,
        product,
      },
      storeData
    );
  }, [product, product.primaryProduct, variationId, storeData]);

  useEffect(() => {
    setShowAddedToCart(Boolean(addedCartItem));
  }, [addedCartItem]);
  const variant = useMemo<PrimaryProductFragment | undefined>(
    () => selectProductVariant(product, variationId),
    [product, variationId]
  );
  const [selectedColorId, setSelectedColorId] = useState(variant?.variationId || undefined);

  const [selectedSizeId, setSelectedSizeId] = useState(selectVariantSize(variant));

  const handleVariationChange = (id: number) => {
    setSelectedColorId(id);
    analytics.track('color_clicked', { productId: product.primaryProductId, source: 'Quickview' }, storeData);
  };

  const handleSizeChange = (sizeId: number) => {
    setSelectedSizeId(selectVariantSize(variant, sizeId));
    analytics.track('size_clicked', { productId: product.primaryProductId, source: 'Quickview' }, storeData);
  };

  const handleQuickView = (addedItem: CartItem | undefined) => {
    if (addedItem) {
      setAddedCartItem(addedItem);
    }
  };

  const closeAll = () => {
    setShowAddedToCart(false);
    setIsOpen(false);
  };

  return (
    <>
      {showAddedToCart && addedCartItem && product && (
        <AddedToCart
          isOpen={showAddedToCart}
          handleClose={closeAll}
          product={product}
          cartItem={addedCartItem}
          source='Quickview'
        />
      )}
      {!showAddedToCart && (
        <Overlay show={isOpen} onClose={() => setIsOpen(false)}>
          <div className={styles.fixedInsetFlex}>
            <Transition.Child
              as={Fragment}
              enter='transition-transform-ease-in-out-duration-300'
              enterFrom='translate-y-full md:-translate-y-full'
              enterTo='translate-y-0'
              leave='transition-transform-ease-in-out-duration-300'
              leaveFrom='translate-y-0'
              leaveTo='translate-y-full md:-translate-y-full'
            >
              <div className={styles.flexCenter}>
                <Dialog.Panel className={styles.dialogPanelStyle}>
                  <button type='button' className={styles.buttonStyle} onClick={() => setIsOpen(false)}>
                    <span className='sr-only'>Close menu</span>
                    <XMarkIcon className={styles.iconStyle} aria-hidden='true' />
                  </button>
                  <Product
                    product={product}
                    variationId={selectedColorId}
                    handleVariationChange={handleVariationChange}
                    handleSizeChange={handleSizeChange}
                    sizeId={selectedSizeId?.id ?? undefined}
                    handleQuickView={handleQuickView}
                    productContainerClassName={styles.productContainer}
                  />
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default QuickView;
